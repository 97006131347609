import React from 'react';
import Box from '@mui/material/Box';
import {Button, Modal, Typography} from '@mui/material';
import ImageMarker, { Marker } from "react-image-marker";
import ReactLassoSelect, { getCanvas } from "react-lasso-select";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: "auto",
  bgcolor: 'background.paper',
  p: 4,
};
// Modal for setting roi on camera
export default function ROI(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [Rx, setRx] = React.useState(1);
    const [Ry, setRy] = React.useState(1);

    // for placing markers
    const [bodyimg,setImg] = React.useState("/noimage.jpeg");
    const [loading,setLoading] = React.useState(false);
    const [points, setPoints] = React.useState(props.points_data);
    const [clippedImg, setClippedImg] = React.useState('');  

    React.useEffect(() => {
        fetchFrame()
    },[])

    const fetchFrame = async() => {
        const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_frame',{method : "POST", headers: {'Content-Type':'application/json'}, body: JSON.stringify({"name":"rtsp","value":props.rtsp})});
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL)
        setLoading(!loading)
    }

    const saveROI = async() => {
        const temp = {}
        for (let i=0; i < points.length; i++){
            temp['point_'+String(i)] = [Rx * points[i]['x'],Ry * points[i]['y']]
        }
        temp["perimeter"] = points.length
        props.update_data(Object.assign({},props.current_data,temp))
        handleClose()
    }

    return (
        <div style={{"padding":"5px 0"}}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant='h4'>Set ROI</Typography>
                    <p style={{fontWeight:"bold"}}>NOTE:-</p>
                    <ul>
                        <li>Select points in clockwise direction to mark region of interest for the camera</li>
                        <li>Click outside the market region to reset the points and mark them again</li>
                    </ul>
                    <Box component="form" autoComplete="off">
                        <ReactLassoSelect
                            value={points}
                            src={bodyimg}
                            onChange={(path) => {
                                setPoints(path);
                            }}
                            onImageLoad={(event) => {setRy(event.target.naturalHeight/event.target.height);setRx(event.target.naturalWidth/event.target.width);setPoints(props.points_data)}}
                            onComplete={(path) => {
                            if (!path.length) return;
                            getCanvas(bodyimg, path, (err, canvas) => {
                                if (!err) {
                                setClippedImg(canvas.toDataURL());
                                }
                            });
                            }}
                            imageStyle={{maxWidth:"800px", height:"auto"}}
                        />
                        {/* <div>
                            <img src={clippedImg} alt="" />
                        </div> */}
                    </Box>
                    <Button onClick={saveROI} variant="outlined" color='primary'>Save ROI</Button>
                </Box>
            </Modal>
            <Button sx={{paddingBottom:"10px"}} onClick={handleOpen} variant="outlined" color='primary'>Edit ROI</Button>
            {/* {points[0]!=undefined ? <><p>Current selected points : {JSON.stringify(points)}</p></>: null} */}
        </div>
    );

}
