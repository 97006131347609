import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ScrollableFeed from 'react-scrollable-feed'
import PropTypes from 'prop-types';
import {List, ListItemButton, ListItemIcon, ListItemText, Collapse, Grid, AppBar, InputAdornment, TextField, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
// icon import
import {AddAPhoto, Preview, Dashboard, ExpandLess, ExpandMore, NotificationsActive, Menu, ReportProblem, Search, CloudDownloadOutlined, ManageAccounts, Psychology, LocationOn, TrendingUp,Summarize} from '@mui/icons-material';
// main import
import DashboardMain from './main/Dashboard'
import AddCamera from './main/AddCamera'
import Provisioning from './main/Provisioning'
import Settinghi from './main/Setttings'
import UserMngt from './main/UserMngt'
import AlertDashboard from './main/AlertDashboard'
import Autosetup from './main/Autosetup'
import FTP from './main/FTP'
// component import
import MenuItem from './components/MenuItem';
import MenuItem2 from './components/MenuItem2';
import Profile from './components/Profile'
import Summary from './main/Summary';
import ClusterOverview from './main/ClusterOverview';
import Emails from './main/Emails';
import CameraFault from './main/CameraFault';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
const drawerWidth = 240;

function ResponsiveDrawer(props) {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6f3e8d'
      }
    }
  });

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleActiveIndex = (value) => {
    if (value!='provisioning'){
      setProvisioning(false);
    }
    document.getElementsByClassName('active_menu')[0].classList.remove('active_menu');
    document.getElementById(value).classList.add('active_menu');
  }
  const { window_sub } = props;

  const highlight = (event) => {
    // document.getElementById("sidebar-" + activeIndex).style.backgroundColor = "#e8e8e8"
    // if (event.currentTarget.id>2){
    //   navigate("/add_camera/" + event.currentTarget.camera_id.replace(' ','_'))
    // }else{
    // navigate("/provisioning/" + event.currentTarget.id.replace(' ','_'))
    // }
    // event.currentTarget.style.backgroundColor = "#e8e8e8"
    handleActiveIndex(event.currentTarget.id)
    setPageTitle(event.currentTarget.textContent)
  }

  const [openProvisioning, setProvisioning] = React.useState(false);
  const [openAdding, setAdding] = React.useState(false);
  const [openAdmin, setAdmin] = React.useState(false);
  const [openSettings, setSettings] = React.useState(false);
  const [openAlert, setAlert] = React.useState(false);
  const [openSite, setSite] = React.useState(false);
  const [openFeature, setFeature] = React.useState(false);

  const [pageTitle, setPageTitle] = React.useState(() => {
    if (window.location.href.split('/').length > 4){
      return "Provisioing"
    } else if (window.location.href.split('/')[3] == 'add_camera'){
      return "Add Camera"
    } else{
      return "Dashboard"
    }
  });

  const handleProvisioning = () => {
    setProvisioning(!openProvisioning);
  };

  const handleAdding = () => {
    setAdding(!openAdding);
  };

  const handleAdmin = () => {
    setAdmin(!openAdmin);
  };
  const handleSettings = () => {
    setSettings(!openSettings);
  };

  const [seedCamera, setSeedCamera] = React.useState("");

  const checkSearchCamera = event => {
      setSeedCamera(event.target.value.trim())
  }

  const [seedTemplate, setSeedTemplate] = React.useState("");

  const checkSearchTemplate = event => {
      setSeedTemplate(event.target.value.trim())
  }


  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClickOpen = (message) => {
    setMessage(message)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const NewNestedList = (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      
      {/* FOR USER MANAGEMENT */}
      {window.localStorage.getItem('dashboard_access') && JSON.parse(window.localStorage.getItem('dashboard_access'))['user_management'].includes("view_user") ?
      <>
        <ListItemButton onClick={() => {navigate('/user_management');setPageTitle("User Management")}} sx={{pl:3}}>
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          <ListItemText primary="User Managment" />
        </ListItemButton>
      </>
      : null}

      {/* Summary page */}

      <>
        <ListItemButton onClick={() => {navigate('/summary');setPageTitle("Safety Improvement Video Analytics Dashboard")}} sx={{pl:3}}>
          <ListItemIcon>
            <TrendingUp />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </ListItemButton>
      </>
      {/* Analytics for filter view */}
      <>
      {/* <Link to={'/overview?view=filters'}> */}
      <ListItemButton onClick={() => { navigate('/alert'); setPageTitle("Alert dashboard")}} sx={{pl:3}}>
          <ListItemIcon>
            <NotificationsActive />
          </ListItemIcon>
          <ListItemText primary="Alert Dashboard" />
        </ListItemButton>
      {/* </Link> */}
      </>

      {/* FOR DASHBOARD OVERVIEW */}
      <>
        <ListItemButton onClick={() => {navigate('/cluster_summary');setPageTitle("Safety Improvement Video Analytics Dashboard")}} sx={{pl:3}}>
          <ListItemIcon>
            <Summarize />
          </ListItemIcon>
          <ListItemText primary="Cluster Overview" />
        </ListItemButton>
      </>

      {/* FOR CAMERA FAULT */}
      <>
        <ListItemButton onClick={() => {navigate('/camera_faulty');setPageTitle("Cameras Offline")}} sx={{pl:3}}>
          <ListItemIcon>
            <ReportProblem />
          </ListItemIcon>
          <ListItemText primary="Live Camera Health" />
        </ListItemButton>
      </>

    </List>
  )

  const drawer = (
    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"100%"}}>
      <div>
        <div style={{padding: "20px",display: "flex",justifyContent: "space-around",alignItems:" center"}}>
          <img src="cil_logo.png" class="custom-logo" alt="" style={{height:"80px"}}></img>
        </div>
        <Divider />
        {NewNestedList}
      </div>
    </div>
  );

  const container = window_sub !== undefined ? () => window_sub().document.body : undefined;
    
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {message}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
          >
              <Menu />
          </IconButton>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{alignItems:"center"}}>
                  <Grid item xs={6}>
                      <Typography variant="h6" noWrap component="div">
                          {pageTitle}
                      </Typography>
                  </Grid>
                  <Grid item xs={6}>
                      <Profile/>
                  </Grid>
              </Grid>

          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        {/* MAIN VIEW */}
        {props['view']=="Dashboard" ? <DashboardMain openDialog={handleClickOpen} />:props['view']=="AddCamera" ? <AddCamera openDialog={handleClickOpen} /> : props['view']=="Provisioning" ? <Provisioning openDialog={handleClickOpen} /> : props['view']=="Settings" ? <Settinghi openDialog={handleClickOpen} /> : props['view']=="AlertDashboard" ? <AlertDashboard openDialog={handleClickOpen} /> : props['view']=="Autosetup" ? <Autosetup openDialog={handleClickOpen} /> : props['view']=="FTP" ? <FTP openDialog={handleClickOpen} /> : props['view']=="summary"? <Summary  openDialog={handleClickOpen} /> : props['view']=="alert"? <AlertDashboard openDialog={handleClickOpen} /> : props['view']=="cluster_summary"? <ClusterOverview  openDialog={handleClickOpen} /> : props['view']=="emails" ? <Emails  openDialog={handleClickOpen} /> : props['view']=="camera_fault" ? <CameraFault  openDialog={handleClickOpen} /> : <UserMngt openDialog={handleClickOpen} />}
      </Box>
    </ThemeProvider>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window_sub: PropTypes.func,
};

export default ResponsiveDrawer;
