import * as React from 'react';
import { Divider, Box, Toolbar, Typography, Modal, capitalize,ToggleButton, ToggleButtonGroup, TextField, Button, MenuItem, Select, InputLabel } from '@mui/material';
import { DataGrid, GridToolbarContainer,GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbar} from '@mui/x-data-grid';
// import ImageUpload from "../components/ImageUpload"
import SyncIcon from "@mui/icons-material/Sync";
import { createSvgIcon } from '@mui/material/utils';
import { useSearchParams } from 'react-router-dom';
import './stylesheets/analytics.css'
import {CChart} from "@coreui/react-chartjs";
import pdfMake from "pdfmake/build/pdfmake";
import { CSVLink, CSVDownload } from "react-csv";
import dateFormat, { masks } from "dateformat";



import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: "80%",
    maxHeight: "80%",
    width: "auto",
    // height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

const drawerWidth = 240;

var rows = [];
var cards = [{"feature_type": "Total Alerts", "count":"?"}];

const SUBMIT_FILTER_STROKE_TIME = 500;

function Main(props) {

    if (!localStorage.getItem('loggedin')){
        window.location = "/login"
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilterParams = urlParams.get('filter')!=null ? urlParams.get('filter') : "";
    const [totalCount, setTotalCount] = React.useState("");
    const [seed, setSeed] = React.useState("");
    const [data, setData] = React.useState(null);
    const [userSelected, setUserSelected] = React.useState(false);
    const [selectTime, setSelectTime] = React.useState(Date.now());
    const [filterParams, setFilterParams] = React.useState(urlFilterParams);
    const [chartLabels, setChartLabels] = React.useState(["Zone Intrusion","Over Manning","Crowd Detection","PPE Not Detected","Fire Smoke","Camera Fault","Tail gating","Idle Time Alert", "Vehicle Count"]);
    const [chartDatasets, setChartDatasets] = React.useState([152493,6465,9982,5619,3602,31071,149,501]);
    const [cluster,setCluster] = React.useState((JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 && JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? 'All' : JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 ? "NCL" : JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0 ? "SECL" : 'All')
    const [selectedSite,setSelectedSite] = React.useState("All")
    const [feature,setFeature] = React.useState("")

    const dateConstruct = new Date();
    let day = dateConstruct.getDate() < 10 ? `0${dateConstruct.getDate()}`: dateConstruct.getDate() ;
    let month = dateConstruct.getMonth() + 1 < 10 ? `0${dateConstruct.getMonth() + 1}`:dateConstruct.getMonth() + 1 ;
    let year = dateConstruct.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`;

    const [startDate,setStartDate] = React.useState(currentDate);
    const [endDate,setEndDate] = React.useState(currentDate)

    var filterModelParams;
    if (filterParams!=""){
      filterModelParams = { columnField: filterParams.split(' ')[0], operatorValue: filterParams.split(' ')[1], value: filterParams.split(' ')[2] }
    }else{
      filterModelParams = {}
    }
    const [currentPage, setCurrentPage] = React.useState(0)

    const [loading, setLoading] = React.useState(true)
    const [imgData, setimgData] = React.useState({'imgPath':"NOT SAVED",'vidPath':"NOT SAVED",'alertType':"?",'cameraName':"?",'alertTimestamp':"?"})

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (loading){
                names()
            }
        }, 2000);
      
        return () => clearInterval(interval);
    }, [loading]);

    React.useEffect(() => {
      names()
    }, [feature,cluster,selectedSite,startDate,endDate,currentPage]);

    const names = async() => {
        setLoading(false)
        const response = await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + `/filter_data?feature_type=${feature}&site_data=dcdc&count=false&cluster=${cluster == 'All' ? null : cluster}&site=${selectedSite == 'All' ? null :selectedSite}&start_alert_timestamp=${startDate}&end_alert_timestamp=${endDate}&page=${currentPage}&cluster_access=${Object.keys(JSON.parse(window.localStorage.getItem('site_access'))).filter(k => JSON.parse(window.localStorage.getItem('site_access'))[k].length>0).join(',')}&site_access=${Object.values(JSON.parse(window.localStorage.getItem("site_access"))).flat(1).join(',')}`,{method : "POST"});
        // const response = (filterParams == null ? await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+":"+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_alerts?page='+currentPage,{method : "GET"}) : await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_alerts?page='+currentPage+'&filter='+String(filterParams),{method : "GET"}))
        const received_data = (await response.json())['data']
        console.log(received_data)
        setData(received_data)
        loadRows(received_data, feature)
    }

    const [exportCsvData,setExportCsvData] = React.useState("")
    React.useEffect(() => {
      if (exportCsvData != "") {
          document.getElementById("csvLink").click()
      }
    }, [exportCsvData]);

    const export_file = (mode) => {
      props.openDialog(`${mode} will be downloaded soon!`)
      fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + `/filter_data?feature_type=${feature}&site_data=dcdc&count=false&cluster=${cluster == 'All' ? null : cluster}&site=${selectedSite == 'All' ? null :selectedSite}&start_alert_timestamp=${startDate}&end_alert_timestamp=${endDate}&page=${currentPage}&export=${true}&cluster_access=${Object.keys(JSON.parse(window.localStorage.getItem('site_access'))).filter(k => JSON.parse(window.localStorage.getItem('site_access'))[k].length>0).join(',')}&site_access=${Object.values(JSON.parse(window.localStorage.getItem("site_access"))).flat(1).join(',')}`,{method : "POST"})
      .then(response => {
        return response.json();
      })
      .then(responseJson => {
        let exportData = []
        responseJson['data']['alert_data'].map((alert) =>{
          exportData.push([alert['feature_type'],alert['cluster'],alert['site'],alert['camera_name'],alert['alert_timestamp']])
        })
        if (mode=="CSV"){
          setExportCsvData([["Feature Type","Cluster","Site","Camera Name","Alert Timestamp"]].concat(exportData))
        }else if (mode=="PDF"){
          var docDefinition = {
              content: [
                {
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    widths: ['auto','auto','auto','auto','auto'],
            
                    body: [[{ text: 'Feature Type', bold: true },{ text: 'Cluster', bold: true },{ text: 'Site', bold: true },{ text: 'Camera Name', bold: true },{ text: 'Alert Timestamp', bold: true }]].concat(exportData)
                  }
                }
              ]
            };
            pdfMake.createPdf(docDefinition).download('exportData.pdf');
        }
      })
      .catch(error => {
        console.error(error);
      });
    }

    const loadRows = (data, feature) => {
        const temp = []
        data['alert_data'].map((value,index) => {
          temp.push(value)
        })
        rows  =  temp
        
        let count = 0;
        let feature_count = 0
        data['count_data'].map((value,index) => {
          if (value['feature_type']==feature){
            feature_count = value['count']
          }
          if (value['feature_type']!="Vehicle Count"){
            count+=value['count']
          }
        })

        let labels = [];
        let datasets = [];
        data['chart_data'].map((value,index) => {
          labels.push(value['daily'])
          datasets.push(value['count'])
        })

        if (feature==""){
          setTotalCount(count)
        }else{
          setTotalCount(feature_count)
        }
        
        setChartLabels(labels)
        setChartDatasets(datasets)
        cards = [{'feature_type':'Total Alerts', "count": count}].concat(data['count_data'])
        if (!userSelected || (Date.now() - selectTime == 15)){
          setimgData({'imgPath':process.env.REACT_APP_SFTP_URL+temp[0]['image_path'],'vidPath':process.env.REACT_APP_SFTP_URL+temp[0]['video_path'],'alertType':temp[0]['feature_type'],'cameraName':temp[0]['camera_name'],'alertTimestamp':temp[0]['alert_timestamp']})
        }
        setLoading(true)
    }
    

    let columns;
    if (feature == "Vehicle Count"){
      columns = [
        { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:120 },
        { field: 'cluster', headerName: 'Cluster', flex:0.12, minWidth:120 },
        { field: 'site', headerName: 'Site', flex:0.12, minWidth:120 },
        { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:120 },
        { field: 'alert_timestamp', headerName: 'Start Alert Timestamp', flex:0.2, minWidth:120, valueGetter: ({ value }) => value && dateFormat(new Date(value), "dd-mm-yyyy, h:MM:ss TT")},
        { field: 'vehicle_count_entry', headerName: 'Vehicle Entry', flex:0.12, minWidth:120 },
        { field: 'vehicle_count_exit', headerName: 'Vehicle Exit', flex:0.12, minWidth:120 },
        { field: 'vehicle_count_type', headerName: 'Type', flex:0.12, minWidth:120 },
        // { field: 'crowd_count', headerName: 'Crowd Count', flex:0.12,minWidth:80}
      ];
    }else if (feature=="Crowd Detection"){
      columns = [        
        { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:180 },
        { field: 'cluster', headerName: 'Cluster', flex:0.2, minWidth:150 },
        { field: 'site', headerName: 'Site', flex:0.2, minWidth:150 },
        { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:150 },
        { field: 'alert_timestamp', headerName: 'Alert Timestamp', flex:0.24, minWidth:240, valueGetter: ({ value }) => value && dateFormat(new Date(value), "dd-mm-yyyy, h:MM:ss TT")},
        { field: 'crowd_swell_detected', headerName: 'Crowd Swell Detected', flex:0.24,minWidth:300}
      ]
    }else if (feature=="Fire Smoke"){
      columns = [        
        { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:120 },
        { field: 'cluster', headerName: 'Cluster', flex:0.2, minWidth:120 },
        { field: 'site', headerName: 'Site', flex:0.2, minWidth:120 },
        { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:120 },
        { field: 'alert_timestamp', headerName: 'Alert Timestamp', flex:0.24, minWidth:120, valueGetter: ({ value }) => value && dateFormat(new Date(value), "dd-mm-yyyy, h:MM:ss TT")},
        { field: 'fire_smoke_intensity', headerName: 'Intensity', flex:0.24,minWidth:120}
      ]
    }else if (feature=="PPE Historical Data"){
      columns = [        
        { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:180 },
        { field: 'cluster', headerName: 'Cluster', flex:0.12, minWidth:150 },
        { field: 'site', headerName: 'Site', flex:0.12, minWidth:150 },
        { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:150 },
        { field: 'alert_timestamp', headerName: 'Alert Timestamp', flex:0.24, minWidth:240, valueGetter: ({ value }) => value && dateFormat(new Date(value), "dd-mm-yyyy, h:MM:ss TT")},
        { field: 'shift_type', headerName: 'Shift Type', flex:0.2,minWidth:150},
        { field: 'comment', headerName: 'Description', flex:0.24,minWidth:400}
      ]
    }else{
      columns = [
          { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:120 },
          { field: 'cluster', headerName: 'Cluster', flex:0.2, minWidth:120 },
          { field: 'site', headerName: 'Site', flex:0.2, minWidth:120 },
          { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:120 },
          // { field: 'ip', headerName: 'Camera IP', flex:0.2, minWidth:50 },
          // { field: 'channel', headerName: 'Channel Number', flex:0.12 },
          { field: 'alert_timestamp', headerName: 'Alert Timestamp', flex:0.24, minWidth:120, valueGetter: ({ value }) => value && dateFormat(new Date(value), "dd-mm-yyyy, h:MM:ss TT")},
          // { field: 'crowd_count', headerName: 'Crowd Count', flex:0.12,minWidth:80}
      ];
    }

    const siteNamesNcl = JSON.parse(window.localStorage.getItem('site_access'))["NCL"]
    const siteNamesSecl = JSON.parse(window.localStorage.getItem('site_access'))["SECL"]
    const getSites = () => {
        if(cluster == 'All') return [...siteNamesNcl,...siteNamesSecl]
        if(cluster == 'NCL') return siteNamesNcl
        
        return siteNamesSecl
    }

    const handleImgData = (ids) => {
        setimgData({'imgPath':process.env.REACT_APP_SFTP_URL+ids['row']['image_path'],'vidPath':process.env.REACT_APP_SFTP_URL+ids['row']['video_path'],'alertType':ids['row']['feature_type'],'cameraName':ids['row']['camera_name'],'alertTimestamp':ids['row']['alert_timestamp']})
        setUserSelected(true)
        document.getElementById('alert_image').click()
        setSelectTime(Date.now())
    }

    const onFilterChange = React.useCallback((filterModel) => {
      if ((filterModel['items'].length!=0)){
        setFilterParams(filterModel['items'][0]['columnField'] + '+' + filterModel['items'][0]['operatorValue'] + '+' + (filterModel['items'][0]['value']!=undefined ? filterModel['items'][0]['value'] : ""))
        names(0, filterModel=filterModel['items'][0]['columnField'] + '+' + filterModel['items'][0]['operatorValue'] + '+' + (filterModel['items'][0]['value']!=undefined ? filterModel['items'][0]['value'] : ""))
      }
    }, []);

    const onPaginationChange = React.useCallback((newPage) => {
        setCurrentPage(newPage)
    },[])

    const [alignment, setAlignment] = React.useState('image');

    const handleToggleChange = (event, newAlignment) => {
        if (newAlignment!=null){
          setAlignment(newAlignment);
        }
    };
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarDensitySelector />
            <Button color='primary' size="small" startIcon={<ExportIcon/>} onClick={() => {export_file("CSV");}}>Export CSV</Button>
            <Button color='primary' size="small" startIcon={<ExportIcon/>} onClick={() => {export_file("PDF")}}>Export PDF</Button>
            {/* <GridToolbarExport /> */}
          </GridToolbarContainer>
        );
      }
    return(
        <>
            <CSVLink filename="exportData.csv" id="csvLink" data={exportCsvData} sx={{display:"none"}}></CSVLink>;
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <img style={style} src={imgData['imgPath']=="NOT SAVED" ? "noimage.jpeg" : imgData['imgPath']}></img>
            </Modal>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                <div id='headerAD'>
                    <div id='clusterUMSection'>
                    {(JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 && JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? <><Button className={ cluster === 'All' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('All');}}>All</Button></> : null}
                      {JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 ? <><Button className={ cluster === 'NCL' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('NCL');}}>NCL</Button></> : null}
                      {JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0 ? <><Button className={ cluster === 'SECL' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('SECL');}}>SECL</Button></> : null}
                    </div>

                    <div  style={{minWidth:"200px",color:"black",fontSize:"16px"}}>
                        <Select name='Site' id="siteFilter" label="Site" value={selectedSite} onChange={(e) => setSelectedSite(e.target.value)} sx={{width:'100%'}}>
                            <MenuItem value={"All"}>All</MenuItem>
                            {getSites().map((site) => 
                                <MenuItem value={site}>{site}</MenuItem>
                            )}
                        </Select>
                    </div>

                    <div className='analytics-filters-date'>
                      <div>
                          <TextField id="startDate" type='date' label="Start Date" InputLabelProps={{ shrink: true, required: true }} variant="outlined" onChange={(e) => setStartDate(e.target.value)} value={startDate} sx={{width:'200px'}}/>
                      </div>
                      <div>
                          <TextField id="endDate" type='date' label="End Date" InputLabelProps={{ shrink: true, required: true }} variant="outlined" onChange={(e) => setEndDate(e.target.value)} value={endDate} sx={{width:'200px'}}/>
                      </div>
                    </div>

                    <Button variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {window.location.href="/emails"}}>EMAILS</Button>
                </div>                

                <div id="postHeaderAD" >
                  <div className='chartAD'>
                    <CChart
                      type="line" 
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            label: `${(feature=="" ? "Total" : feature)} Alert Count (${startDate==endDate ? "per hour" : "per day"})`,
                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                            borderColor: "rgba(220, 220, 220, 1)",
                            pointBackgroundColor: "#6f3e8d",
                            pointBorderColor: "#6f3e8d",
                            data: chartDatasets
                          } 
                        ],
                      }}
                    />
                  </div>
                  <div id = "cards" className="cardsAD">
                      {cards.map((value, index) =>
			(value['feature_type'] != "Fault" ?
                          <>
                            <div onClick={value['feature_type']!='Total Alerts' ? (event)=>{feature==value['feature_type'] ? setFeature("") : setFeature(value['feature_type'])} : null} class="alertCard" id={index} style={feature==value['feature_type'] ? {border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", minWidth:"200px", display:'flex',flexDirection:"column",padding:"20px",marginBottom:'20px',marginRight:'20px',backgroundColor:"#e8e8e8"} : {border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", minWidth:"200px", display:'flex',flexDirection:"column",padding:"20px",marginBottom:'20px',marginRight:'20px'}}>
                                <Typography variant='h6'>{capitalize(value['feature_type'])}</Typography>
                                <Typography variant='h4' align="right">{value['count']}</Typography>
                            </div>
                          </> : null
                          ) 
                      )}
                      <div style={{width:"30%",minWidth:"200px",marginRight:'20px',height:"1px"}}></div>
                      <div style={{width:"30%",minWidth:"200px",marginRight:'20px',height:"1px"}}></div>
                  </div>
                </div>
                
                <Divider/>
                
                <div id='alertMain' >
                    <div id="alertGrid" style={{width:"70%",height: "600px"}}>
                        <DataGrid
                            disableColumnFilter 
                            getRowId={(r) => r.ID}
                            // loading = {true}
                            rows={rows}
                            columns={columns}
                            pageSize={100}
                            rowsPerPageOptions={[100]}
                            sx = {{fontSize:"1rem"}}
                            onRowClick = {(ids) => {handleImgData(ids)}}
                            components={{
                                // Toolbar: GridToolbar,
                                Toolbar: CustomToolbar,
                              }}
                            componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },csvOptions: { allRows: true } } }}
                            initialState={{
                                filter:{
                                    filterModel: {items: [filterModelParams],},
                                }
                            }}
                            filterMode="server"
                            onFilterModelChange={onFilterChange}
                            paginationMode="server"
                            onPageChange={onPaginationChange}
                            rowCount={totalCount} 

                        />
                    </div>
                    <div id="alertImage" style={{width:"30%"}}>
                        <div style={{marginBottom:"20px"}}>
                            <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleToggleChange} aria-label="Platform" style={{width:"100%"}}>
                                <ToggleButton value="image" id="alert_image" style={{width:"50%"}}>Image</ToggleButton>
                                <ToggleButton value="video" id="alert_video" style={{width:"50%"}}>Video</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            {alignment=='image' ? <img style={{maxWidth:"100%",height:"auto"}} src={imgData['imgPath']=="NOT SAVED" ? process.env.REACT_APP_SFTP_URL + "noimage.jpeg" : imgData['imgPath']} alt="alert_image" onClick={handleOpen}/> : 
                            <video controls>
                                <source src={imgData['vidPath']=="NOT SAVED" ? process.env.REACT_APP_SFTP_URL + "noimage.jpeg" : imgData['vidPath']} type="video/mp4"/>
                                Your browser does not support HTML video.
                            </video>}
                            <span id='alert_type' style={{color:'red',textTransform:"capitalize"}}>Alert Type : {imgData['alertType']}</span>
                            <span id='camera_id' style={{color:'green',textTransform:"capitalize"}}>Camera Name : {imgData['cameraName']}</span>
                            <span id='alert_timestamp' style={{color:'blue',textTransform:"capitalize"}}>Alert Timestamp : {imgData['alertTimestamp']}</span>
                        </div>
                    </div>
                    <div>
                      
                  </div>
                </div>

                
                {/* <ImageUpload /> */}
            </Box>
        </>
    );
}

export default Main;
