import * as React from 'react';
import {Box,Toolbar, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import SignIn from '../components/SignIn';
import ForgotPassword from '../components/ForgotPassword';

function Main(props) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClickOpen = (message) => {
        setMessage(message)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return(
        <>
            <Box component="main">
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle id="alert-dialog-title">
                        {message}
                        </DialogTitle>
                        <DialogActions>
                        <Button onClick={handleClose} autofocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                    <div style={{backgroundImage:`url(${'background.jpg'})`,backgroundPosition:"center",width:"100vw",height:"100vh", backgroundSize:"cover",display:'flex',justifyContent:"flex-start",alignItems:"center"}}>
                    {props.view == "SignIn" ? <SignIn openDialog={handleClickOpen}/> : <ForgotPassword openDialog={handleClickOpen}/>}
                </div>
                
            </Box>
        </>
    )
}

export default Main;
