import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import PageNotFound from './PageNotFound';
import UnauthorizedAccess from './UnauthorizedAccess';
import Login from './/main/Login';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {window.localStorage.getItem('dashboard_access') && JSON.parse(window.localStorage.getItem('dashboard_access'))['alert_dashboard'].includes("view_alert") ? 
        <>
          <Route exact path="/" element={<App view = "summary"/>} /> 
          <Route exact path="/summary" element={<App view = "summary"/>} />
          <Route exact path="/alert" element={<App view = "alert"/>} />
          <Route exact path="/camera_faulty" element={<App view = "camera_fault"/>} />
          <Route exact path="/cluster_summary" element={<App view = "cluster_summary"/>} />
          <Route exact path="/emails" element={<App view = "emails"/>} />
        </>
      : window.localStorage.getItem('dashboard_access') && !JSON.parse(window.localStorage.getItem('dashboard_access'))['alert_dashboard'].includes("view_alert") ? 
        <>
          <Route exact path="/" element={<UnauthorizedAccess />}/> 
          <Route exact path="/summary" element={<UnauthorizedAccess />}/> 
          <Route exact path="/alert" element={<UnauthorizedAccess />}/>
          <Route exact path="/camera_faulty" element={<UnauthorizedAccess />}/>
          <Route exact path="/cluster_summary" element={<UnauthorizedAccess />} />
          <Route exact path="/emails" element={<UnauthorizedAccess />} />
        </>
      : <>
          <Route exact path="/" element={<Login view="SignIn"/>}/>
          <Route exact path="/summary" element={<Login view="SignIn"/>}/>
          <Route exact path="/alert" element={<Login view="SignIn"/>}/>
          <Route exact path="/camera_faulty" element={<Login view="SignIn"/>}/>
          <Route exact path="/cluster_summary" element={<Login view="SignIn"/>} />
          <Route exact path="/emails" element={<Login view="SignIn" />} />
        </>
      }

      {window.localStorage.getItem('dashboard_access') && JSON.parse(window.localStorage.getItem('dashboard_access'))['user_management'].includes("view_user") ? 
        <Route path="/user_management" element={<App view = "UserMngt"/>} />
      : window.localStorage.getItem('dashboard_access') && !JSON.parse(window.localStorage.getItem('dashboard_access'))['user_management'].includes("view_user") ? 
        <Route path="/user_management" element={<UnauthorizedAccess />}/> 
      : <Route path="/user_management" element={<Login view="SignIn"/>}/>}

      <Route path="*" element={<PageNotFound/>} />
      <Route path="/login" element={<Login view="SignIn"/>} />
      <Route path="/forgot_password" element={<Login view="ForgotPassword"/>} />
      {/* <Route exact path="/autosetup" element={<App view = "Autosetup"/>} /> */}
      {/* <Route path="/settings" element={<App view = "Settings"/>} /> */}
    </Routes>
  </BrowserRouter>,

  document.getElementById("root")
);

reportWebVitals();


