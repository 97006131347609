
import React from 'react';

export default function UnauthorizedAccess(){
    return(
        <>
            <div style={{margin:"20px"}}>
                <h1>401 Error</h1>
                <h1>Unauthorized Access</h1>
            </div>
        </>
    )
}