import * as React from 'react';
import {Button, CssBaseline, TextField, Link, Box} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Palette } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.deepsightlabs.com/">
        DeepsightAI Labs Pvt. Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn(props) {

  const [searchParams, setSearchParams] = useSearchParams();

  const req = async(data) => {
    const response = await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/login', {
      method:"POST",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(data)
    })
    const returned_data = await response.json()
    if (returned_data.status_code!=200 && returned_data.status_code!=201){
      props.openDialog(returned_data.message);
    }else{
      Object.entries(returned_data['data']).map(([key,value])=>{
        if (key=="user"){
          localStorage.setItem(key,JSON.stringify(value))
        }else{
          localStorage.setItem(key,value)
        }
      })
      window.location.href = '/'
    }
  }

  if (searchParams.get("code")!=null){
    req({"env":process.env.REACT_APP_ENV,"mode":"sso","login_code": searchParams.get("code")})
  }

  // const history = new URLSearchParams(window.location.search).get('history')!=null ? new URLSearchParams(window.location.search).get('history') : ''

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    req({"env":process.env.REACT_APP_ENV, "mode":"direct","username": data.get('username'), "password":data.get('password')})
  };

  return (
    <Container component="main" maxWidth="xs" sx={{background:"white"}}>
    <CssBaseline />
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
        <div style={{paddingTop: "20px",width:"100%",display: "flex",justifyContent: "center",alignItems:" center"}}>
          <img src="cil_logo.png" class="custom-logo" alt="" style={{height:"70px"}}></img>
        </div>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth id="username" label="Email" name="username" autoComplete="username" autoFocus/>
          <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password"/>
          <Link href="/forgot_password">Forgot Password?</Link>
          <Button  type="submit"  fullWidth  variant="contained"  sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Button type="a" href={process.env.REACT_APP_LOGIN} fullWidth  variant="contained">
            Sign In with Cognito
          </Button>
        </Box>
    </Box>
    <Copyright sx={{ mt: 4, mb: 4 }} />
    </Container>
  );
}
