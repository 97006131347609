import React from 'react';
import { TextField, Checkbox, Divider,Slider, unsupportedProp, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Box, Button, Typography, Modal, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import PCard from './FeatureCard';
import { ReactDOM } from 'react';
import ROI from './ROI'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth:"80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight:"80%",
  overflowY:"scroll"
};

// Modal for adding/editing a camera
export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const handleClickOpen = (message) => {
    setDialogMessage(message)
    setDialogOpen(true);
  };

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  const [data, setData] = React.useState(()=>{
    if (props.data!=null){
      return props.data
    }else{
      let temp = null;
      {feature == 'intrusion' ? temp = {"perimeter":0,"email_list":'','threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['intrusion']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} :
        feature == 'multiple_person' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['multiple_person']['default_value_in_slider']),'threshold':parseFloat(featureData['settings']['multiple_person']['mul_people_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['multiple_person']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
        feature == 'crowd_count' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['crowd_count']['crowd_max_value']),'threshold':parseFloat(featureData['settings']['crowd_count']['crowd_threshold']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['crowd_count']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
        feature == 'idle_time' ? temp = {"email_list":'','min_time':5,'threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} : 
        feature == 'fire_smoke' ? temp = {"email_list":'','fire_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'smoke_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
        feature == 'ppe_detection' ? temp = {"email_list":'','no_vest_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_show_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_helmet_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
        temp = {"email_list":'','threshold':30,'perimeter_overlap':30,'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} 
      }
      return temp;
    }
  });


  // setting roi points
  const [temp_points, setTempPoints] = React.useState(() => {
    if (props.data!=null){
      const temp_poinst = [];
      (Object.keys(props.data).filter(k => k.startsWith('point'))).forEach(key => {
        temp_poinst.push({'x':props.data[key][0],'y':props.data[key][1]})
      })
      return temp_poinst
    } else{
      return [];
    }
  })

  // set emailList - multiple added emails
  const [emailList, setEmailList] = React.useState(() => {
    if (props.data!=null){
      const temp = []
      props.data['email_list'].split(',').map((value,index) => {
        temp.push({"email":value})
      })
      return temp
    }else{
     return [{"email":""}]
    }
  })
  const [emailListButton,setEmailListButton] = React.useState('Add')
  const addEmail = () => {
    if (emailListButton == "Add"){
      setEmailList(emailList.concat([{"email":""}]))
      // setEmailListButton('Save')
    } else{
      // const pDiv = document.getElementById('email_list');
      // const cDiv = pDiv.children;
      // for (let i = 0; i < cDiv.length; i++) {
      //   cDiv[i].children[1].children[0].style.backgroundColor = "#e8e8e8"
      //   cDiv[i].children[1].children[0].disabled = true
      // }
      setEmailListButton('Add')
    }
  }
  const updateEmailList = (event) => {
    const new_arr = emailList
    new_arr[event.target.id] = {"email":event.target.value}
    setEmailList(new_arr)
  }


  // fetching features available in license. Only these features can be added onto a camera
  const [featureData, setFeatureData] = React.useState(null);
  const [loading, setLoading] = React.useState(true)
  var [feature, setFeature] = React.useState(null);

  React.useEffect(() => {
    fetchFeatures()
  },[])

  const fetchFeatures = async() => {
      const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/fetch_features',{method : "GET"});
      const received_data = await response.json()
      setFeatureData(received_data)
      setFeature(received_data[0])
      setLoading(!loading)
  }

  if (featureData!=null && feature==null){
    {props.data!=null ? setFeature(props.name) : setFeature(featureData['features'][0])}
  }

  const saveFeature = () => {
    if ("list" in props){
      const new_data = Object.assign({},data)
      const concatEmail = [];
      emailList.map((key,value) => {
        concatEmail.push(key['email'])
      })
      new_data['email_list'] = concatEmail.join()

      if (props.text=='Edit'){
        handleClickOpen('Feature Updated')
      } else if (feature in props.list){
        handleClickOpen('Current feature will be replaced!')
      } 

      props.list[feature] = new_data
      props.addCard(Object.assign({},props.list))

      // resetting feature data
      if (props.data!=null){
        const temp = []
        props.data['email_list'].split(',').map((value,index) => {
          temp.push({"email":value})
        })
        setEmailList(temp)
        setData(props.data)
      }else{
        setEmailList([{"email":""}])
        let temp = null;
        {feature == 'intrusion' ? temp = {"perimeter":0,"email_list":'','threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['intrusion']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} :
          feature == 'multiple_person' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['multiple_person']['default_value_in_slider']),'threshold':parseFloat(featureData['settings']['multiple_person']['mul_people_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['multiple_person']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
          feature == 'crowd_count' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['crowd_count']['crowd_max_value']),'threshold':parseFloat(featureData['settings']['crowd_count']['crowd_threshold']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['crowd_count']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
          feature == 'idle_time' ? temp = {"email_list":'','min_time':5,'threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} : 
          feature == 'fire_smoke' ? temp = {"email_list":'','fire_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'smoke_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
          feature == 'ppe_detection' ? temp = {"email_list":'','no_vest_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_show_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_helmet_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
          temp = {"email_list":'','start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} 
        }
        setData(temp);
      }
    }else{
      handleClickOpen('Some Error Occurred')
    }
    setOpen(false);
  }

  const handleChange = (event) => {
    setFeature(event.target.value);
    let temp = null;
    {event.target.value == 'intrusion' ? temp = {"perimeter":0,"email_list":'','threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['intrusion']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} :
      event.target.value == 'multiple_person' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['multiple_person']['default_value_in_slider']),'threshold':parseFloat(featureData['settings']['multiple_person']['mul_people_thresh']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['multiple_person']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
      event.target.value == 'crowd_count' ? temp = {"perimeter":0,"email_list":'','min_people':Number(featureData['settings']['crowd_count']['crowd_max_value']),'threshold':parseFloat(featureData['settings']['crowd_count']['crowd_threshold']),'perimeter_overlap':parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['crowd_count']),'start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} : 
      event.target.value == 'idle_time' ? temp = {"email_list":'','min_time':5,'threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} : 
      event.target.value == 'fire_smoke' ? temp = {"email_list":'','fire_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'smoke_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
      event.target.value == 'ppe_detection' ? temp = {"email_list":'','no_vest_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_show_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'no_helmet_threshold':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'perimeter_overlap':parseFloat(featureData['settings']['intrusion']['intr_det_thresh']),'start_time':"00:00:00",'end_time':"23:59:00"} :
      temp = {"email_list":'','start_time':"00:00:00",'end_time':"23:59:00","video_saving_check":true} 
    }
    const new_data = Object.assign({},temp)
    setData(temp)
  };

    const updateData = (event,component_id) => {
    const new_data = Object.assign({},data)
    if (component_id == "sound_alarm"){
      new_data[component_id] = event.target.checked==true ? true : false
    }else if (component_id.includes("threshold") || component_id.includes("perimeter_overlap")){
      new_data[component_id] = event.target.value / 100
    }else if (component_id == "min_people"){
      new_data[component_id] = Number(event.target.value)
    }else{
      new_data[component_id] = event.target.value
    }
    setData(new_data)
  }

  if (!loading){
    return (
      <div>
        <Dialog open={open} onClose={handleClickClose}>
          <DialogTitle id="alert-dialog-title">
            {dialogMessage}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClickClose} autoFocus>OK</Button>
          </DialogActions>
        </Dialog>
        <Button onClick={handleOpen} variant="outlined" color='primary'>+ {props.text} Feature</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
              <Box component="form" sx={{'& > :not(style)': { my: 1,width:'100%' }, }} autoComplete="off" onSubmit={saveFeature}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Feature</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={feature} label="AIFeature" onChange={handleChange}>
                    {props.text=='Add' ? 
                      featureData['features'].map((value,index) => 
                        <MenuItem value={value}>{value=="intrusion" ? "zone_intrusion" : value=="multiple_person" ? "crowd_detection" : value=="crowd_count" ? "over_manning" : value}</MenuItem>
                      )
                    :
                      <MenuItem value={props.name}>{props.name=="intrusion" ? "zone_intrusion" : props.name=="multiple_person" ? "crowd_detection" : props.name=="crowd_detection" ? "over_manning" : props.name}</MenuItem>
                    }
                  </Select>

                  <Typography variant='h5' sx={{pt:3,pb:1}}>Set Paramaters</Typography>

                  <div id="email_list" >
                  {emailList.map((key, value) => 
                    (key['email']!="" ? <TextField sx={{my:1, width:"100%"}} id={value} label="Email" variant="outlined" defaultValue={key['email']} onChange = {updateEmailList} type="email" errorText={'Please enter correct email'}/> : 
                    <TextField sx={{my:1, width:"100%"}} id={value} label="Email" variant="outlined" onChange = {updateEmailList} type="email" errorText={'Please enter correct email'} />)
                  )}
                  </div>
                  <Button color="primary" variant='outlined' onClick={addEmail}>{emailListButton} Email</Button>

                  {/* <TextField id="email_list" label="Email" variant="outlined" defaultValue={props.data!=null ? props.data.email_list : null} onChange = {(event)=>{updateData(event,"email_list")}} /> */}

                  <div className='threshold' style={{"padding":"10px 0"}}>
                    {feature == 'intrusion' ? <><Typography>Threshold</Typography><Slider aria-label="Threshold" id="threshold" defaultValue={props.data != null ? props.data['threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"threshold")}} /></> :
                      feature == 'multiple_person' ? <><Typography>Threshold</Typography><Slider aria-label="Threshold" id="threshold" defaultValue={props.data != null ? props.data['threshold'] * 100: parseFloat(featureData['settings']['multiple_person']['mul_people_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"threshold")}} /></> : 
                      feature == 'crowd_count' ? <><Typography>Threshold</Typography><Slider aria-label="Threshold" id="threshold" defaultValue={props.data != null ? props.data['threshold'] * 100: parseFloat(featureData['settings']['crowd_count']['crowd_threshold']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"threshold")}} /></> : 
                      feature == 'idle_time' ? <><Typography>Threshold</Typography><Slider aria-label="Threshold" id="threshold" defaultValue={props.data != null ? props.data['threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"threshold")}} /></> : 
                      feature == 'fire_smoke' ? <><Typography>Fire Threshold</Typography><Slider aria-label="Fire Threshold" id="fire_threshold" defaultValue={props.data != null ? props.data['fire_threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"fire_threshold")}} /><Typography>Smoke Threshold</Typography><Slider aria-label="Smoke Threshold" id="smoke_threshold" defaultValue={props.data != null ? props.data['smoke_threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"smoke_threshold")}} /></> :
                      feature == 'ppe_detection' ? <><Typography>No Vest Threshold</Typography><Slider aria-label="No Vest Threshold" id="no_vest_threshold" defaultValue={props.data != null ? props.data['no_vest_threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"no_vest_threshold")}} /><Typography>No Show Threshold</Typography><Slider aria-label="No Show Threshold" id="no_show_threshold" defaultValue={props.data != null ? props.data['no_show_threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"no_show_threshold")}} /><Typography>No Helmet Threshold</Typography><Slider aria-label="No Helmet Threshold" id="no_helmet_threshold" defaultValue={props.data != null ? props.data['no_helmet_threshold'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"no_helmet_threshold")}} /></> :
                      null 
                    }
                  </div>
                  <Divider/>

                  {/* feature specific space */}
                  {feature == 'multiple_person' ? <><div className='feature_specific' style={{"padding":"10px 0"}}><label for="min_people" style={{paddingBottom:"5px 0"}}>Minimum people</label><TextField id="min_people" type='number' InputProps={{ inputProps: { min: 1, max: 20 } }} defaultValue={props.data != null ? Number(props.data['min_people']) : Number(featureData['settings']['multiple_person']['default_value_in_slider'])} variant="outlined" onChange = {(event)=>{updateData(event,"min_people")}}/></div><Divider/></> : 
                    feature == 'crowd_count' ? <><div className='feature_specific' style={{"padding":"10px 0"}}><label for="min_people" style={{paddingBottom:"5px 0"}}>Minimum people</label><TextField id="min_people" type='number' InputProps={{ inputProps: { min: 1, max: 20 } }} defaultValue={props.data != null ? Number(props.data['min_people']) : Number(featureData['settings']['crowd_count']['crowd_max_value'])} variant="outlined" onChange = {(event)=>{updateData(event,"min_people")}}/></div><Divider/></> : 
                    feature == 'idle_time' ? <><div className='feature_specific' style={{"padding":"10px 0"}}><label for="min_time" style={{paddingBottom:"5px 0"}}>Minimum Time (in seconds)</label><TextField id="min_time" type='number' defaultValue={props.data != null ? props.data['min_time'] : null} variant="outlined" onChange = {(event)=>{updateData(event,"min_time")}}/></div><Divider/></> : 
                    null
                  }
                  
                  {process.env.REACT_APP_ROI_SHOW == "true" ? 
                    <>
                      <div className='roi' style={{"padding":"10px 0"}}>
                        {/* instead of separate condition for every feature thresh old commonise it. */}
                        {feature == 'intrusion' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['intrusion']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> :
                          feature == 'multiple_person' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])['multiple_person']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> : 
                          feature == 'crowd_count' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(JSON.parse(featureData['settings']['roi_flags']['perimeter_overlap_dict'])["crowd_count"]) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> : 
                          feature == 'idle_time' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> : 
                          feature == 'fire_smoke' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> :
                          feature == 'ppe_detection' ? <><ROI rtsp={props.camera_rtsp} update_data = {setData} current_data = {data} points_data = {temp_points}/><Typography>Overlap Threshold</Typography><Slider aria-label="Temperature" id="perimeter_overlap" defaultValue={props.data != null ? props.data['perimeter_overlap'] * 100: parseFloat(featureData['settings']['intrusion']['intr_det_thresh']) * 100} valueLabelDisplay="auto" min={0} max={100} onChange = {(event)=>{updateData(event,"perimeter_overlap")}} /></> :
                          null 
                        }
                      </div>
                      <Divider/>
                    </>
                    :null
                  }

                  

                  <label for="start_time" style={{margin:"10px 0"}}>Start Time</label>
                  <TextField id="start_time" type='time' variant="outlined" defaultValue={props.data!=null ? props.data.start_time : "00:00:00"}  onChange = {(event)=>{updateData(event,"start_time")}}/>
                  <label for="end_time" style={{margin:"10px 0"}}>End Time</label>
                  <TextField id="end_time" type='time' variant="outlined" defaultValue={props.data!=null ? props.data.end_time : "23:59:00"} onChange = {(event)=>{updateData(event,"end_time")}}/>
      
                  <Button variant='outlined' type='submit' sx={{mt:"20px"}}>{props.text=='Edit' ? "Save" : "Add"} Feature</Button>
                </FormControl>
              </Box>
          </Box>
        </Modal>
      </div>
    );
  }

}
