import React from 'react'
import { Divider, Box, Toolbar, Typography, Modal, capitalize,ToggleButton, ToggleButtonGroup, TextField, Button, Select, MenuItem,Card, CardActions, CardContent,CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './stylesheets/analytics.css'

const drawerWidth = 240;

function Summary(props) {
    console.log([JSON.parse(window.localStorage.getItem("user"))].map(({ ID,first_name,user_name }) => ({ ID,first_name,user_name }))[0])

    const navigate = useNavigate()

    const [allData, setAllData] = React.useState([{feature_type: 'Zone Intrusion', count: 0},{feature_type: 'Camera Fault', count: 0},{feature_type: 'PPE Not Detected', count: 0},{feature_type: 'Tail gating', count: 0},{feature_type: 'Fire Smoke', count: 0},{feature_type: 'Crowd Detection', count: 0} ,{feature_type: 'Over Manning', count: 0},{feature_type: 'Idle Time Alert', count: 0},{feature_type: 'Vehicle Count', count: 0}])
    const [nclData, setNclData] = React.useState([{feature_type: 'Zone Intrusion', count: 0},{feature_type: 'Camera Fault', count: 0},{feature_type: 'PPE Not Detected', count: 0},{feature_type: 'Tail gating', count: 0},{feature_type: 'Fire Smoke', count: 0},{feature_type: 'Crowd Detection', count: 0} ,{feature_type: 'Over Manning', count: 0},{feature_type: 'Idle Time Alert', count: 0},{feature_type: 'Vehicle Count', count: 0}])
    const [seclData, setSeclData] = React.useState([{feature_type: 'Zone Intrusion', count: 0},{feature_type: 'Camera Fault', count: 0},{feature_type: 'PPE Not Detected', count: 0},{feature_type: 'Tail gating', count: 0},{feature_type: 'Fire Smoke', count: 0},{feature_type: 'Crowd Detection', count: 0} ,{feature_type: 'Over Manning', count: 0},{feature_type: 'Idle Time Alert', count: 0},{feature_type: 'Vehicle Count', count: 0}])
    const [cameraNotRunning,setCameraNotRunning] = React.useState({"ALL":[{"count":0}],"NCL":[{"count":0}],"SECL":[{"count":0}]})

    const dateConstruct = new Date();
    let day = dateConstruct.getDate() < 10 ? `0${dateConstruct.getDate()}`: dateConstruct.getDate() ;
    let month = dateConstruct.getMonth() + 1 < 10 ? `0${dateConstruct.getMonth() + 1}`:dateConstruct.getMonth() + 1 ;
    let year = dateConstruct.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`;

    React.useEffect(() => {
      filters()
      fetchCameraData({})
    }, [])

    const findMaxAlerts = (data) => {
        let maxFeature = ""
        let maxAlerts = 0
        for (let i=0;i<data.length;i++){
            if (data[i]['count']>maxAlerts){
                maxAlerts = data[i]['count']
                maxFeature = data[i]['feature_type']
            }
        }
        return maxFeature;
    }
    
    const filters = async () => {
        let response;let returned_response;
        response = await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME+`/summary?site_access=${Object.values(JSON.parse(window.localStorage.getItem("site_access"))).flat(1).join(',')}`,{method : "GET"});
        returned_response = await response.json()
        console.log(returned_response)
        if (returned_response.status_code!=200 && returned_response.status_code!=201){
            props.openDialog(returned_response.message)
        }else{
            setAllData(returned_response['data']['ALL'])
            setNclData(returned_response['data']['NCL'])
            setSeclData(returned_response['data']['SECL'])
        }
    }

    const fetchCameraData = async({cluster = 'All',selectedSite = "All",startDate = currentDate}) => {
        const response = await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + `/camera_fault_data?site_access=${Object.values(JSON.parse(window.localStorage.getItem("site_access"))).flat(1).join(',')}`,{method : "GET"});
        const returned_response = await response.json()
        if (returned_response.status_code!=200 && returned_response.status_code!=201){
            props.openDialog(returned_response.message)
        }else{
            setCameraNotRunning(returned_response['data'])
        }
    }

  return (
    <>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar />
            <h3>Hi {JSON.parse(window.localStorage.getItem("user"))['first_name']},</h3>
            <div className="summary_view">
                {(JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 && JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? 
                <>
                    <Card className="summary_card">
                        <CardHeader title={"ALL"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                        <CardContent>
                            <Typography sx={{ fontWeight:700,color:"#6f3e8d"}}>
                                Summary Received
                            </Typography>
                            <p>Total Features Running : {allData.length}</p>
                            <p>Most Alerts in Feature : {findMaxAlerts(allData)}</p>
                            <p>Total Cameras : {Object.values(JSON.parse(window.localStorage.getItem('site_access'))).flat(1).length * 30}</p>
                            <p>Sites Included : {Object.values(JSON.parse(window.localStorage.getItem('site_access'))).flat(1).join(', ')}</p>
                            <Divider/>
                            <Typography variant='h6' padding="10px" sx={{textAlign:"center"}}>FEATURES RUNNING : {allData?.count_data?.length}</Typography>
                            <div style={{display:"flex",flexWrap:"wrap",padding:"20px 0px",justifyContent:"space-around"}}>
                                {allData.map((feature) => {
                                    return <>
                                        <div class="alertCard" style={{border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", display:'flex',flexDirection:"column",padding:"20px",margin:'10px 10px 0px 0px',minWidth:"110px"}}>
                                            <Typography sx={{fontSize:"16px",marginBottom:"10px",overflowWrap:"break-word"}}>{capitalize(feature.feature_type)}</Typography>
                                            <Typography variant='span' sx={{fontWeight:"bold"}}>{feature.count}</Typography>
                                        </div>
                                    </>
                                })}
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>
                            </div>
                        </CardContent>

                    </Card>
                </> : null}

                {(JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0) ? 
                <>
                    <Card className="summary_card">
                        <CardHeader title={"NCL"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                        <CardContent>
                            <Typography sx={{ fontWeight:700,color:"#6f3e8d"}}>
                                Summary Received
                            </Typography>
                            <p>Total Features Running : {nclData.length}</p>
                            <p>Most Alerts in Feature : {findMaxAlerts(nclData)}</p>
                            <p>Total Cameras : {JSON.parse(window.localStorage.getItem('site_access'))['NCL'].length * 30}</p>
                            <p>Sites Included : {JSON.parse(window.localStorage.getItem('site_access'))['NCL'].join(', ')}</p>
                            <Divider/>
                            <Typography variant='h6' padding="10px" sx={{textAlign:"center"}}>FEATURES RUNNING : {nclData?.count_data?.length}</Typography>
                            <div style={{display:"flex",flexWrap:"wrap",padding:"20px 0px",justifyContent:"space-around"}}>
                                {nclData.map((feature) => {
                                    return <>
                                        <div class="alertCard" style={{border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", display:'flex',flexDirection:"column",padding:"20px",margin:'10px 10px 0px 0px', minWidth:"110px"}}>
                                            <Typography sx={{fontSize:"16px",marginBottom:"10px",overflowWrap:"break-word"}}>{capitalize(feature.feature_type)}</Typography>
                                            <Typography variant='span' sx={{fontWeight:"bold"}}>{feature.count}</Typography>
                                        </div>
                                    </>
                                })}
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>                            
                            </div>
                        </CardContent>

                    </Card>
                </> : null}

                {(JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? 
                <>
                    <Card className="summary_card">
                        <CardHeader title={"SECL"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                        <CardContent>
                            <Typography sx={{ fontWeight:700,color:"#6f3e8d"}}>
                                Summary Received
                            </Typography>
                            <p>Total Features Running : {seclData.length}</p>
                            <p>Most Alerts in Feature : {findMaxAlerts(seclData)}</p>
                            <p>Total Cameras : {JSON.parse(window.localStorage.getItem('site_access'))['SECL'].length * 30}</p>
                            <p>Sites Included : {JSON.parse(window.localStorage.getItem('site_access'))['SECL'].join(', ')}</p>
                            <Divider/>
                            <Typography variant='h6' padding="10px" sx={{textAlign:"center"}}>FEATURES RUNNING : {seclData?.count_data?.length}</Typography>
                            <div style={{display:"flex",flexWrap:"wrap",padding:"20px 0px",justifyContent:"space-around"}}>
                                {seclData.map((feature) => {
                                    return <>
                                        <div class="alertCard" style={{border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", display:'flex',flexDirection:"column",padding:"20px",margin:'10px 10px 0px 0px', minWidth:"110px"}}>
                                            <Typography sx={{fontSize:"16px",marginBottom:"10px",overflowWrap:"break-word"}}>{capitalize(feature.feature_type)}</Typography>
                                            <Typography variant='span' sx={{fontWeight:"bold"}}>{feature.count}</Typography>
                                        </div>
                                    </>
                                })}
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>
                                <div class="alertCard" style={{width:"30%",minWidth:"110px",height:"1px"}}></div>                            
                            </div>
                        </CardContent>

                    </Card>
                </> : null}
            </div> 
            <Button variant="outlined" onClick={() => navigate('/cluster_summary')}>Click to View Details</Button>
        </Box>
    </>
  )
}

export default Summary
