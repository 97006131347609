import React, { useEffect ,useState} from 'react'
import './stylesheets/analytics.css'
import { Divider, Box, Toolbar, Link,Typography, Modal, capitalize,ToggleButton, ToggleButtonGroup, TextField, Button,Card, CardActions, CardContent,CardHeader } from '@mui/material';
import {Email, AccountCircle} from '@mui/icons-material';

const drawerWidth = 240;

function Emails() {

    const [dataMap, setDataMap] = useState({})
    const [cluster, setCluster] = useState('All')

    const makeCalls = async () => {
        let map = {SAFETY:[],SECURITY:[],GENERAL:[]}
        let parameters = ['SAFETY','SECURITY','GENERAL']
        for(let i = 0 ; i < parameters.length ; i++){
            const response = await fetch(`https://`+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME+`/email_data?site_access=${Object.values(JSON.parse(window.localStorage.getItem("site_access"))).flat(1).join(',')}&department=${parameters[i]}`,{method : "POST"});
            const received_data = (await response.json())['data'] 
            received_data.alert_data.forEach(element => {
                map[parameters[i]].push(element.email)
            });
       }
       console.log(map)
       setDataMap(map)
    }

    useEffect(() => {

        makeCalls()

    },[cluster])
  return (
    <>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar/>
            {dataMap ? 
            <div className='email-wrapper'>
                <Card sx={{ maxWidth: 700, width:"30%" ,border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",borderRadius:"20px"}}>
                    <CardHeader title={"SAFETY"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                    <CardContent>
                        {dataMap?.SAFETY?.map((email)=>{
                            return <>
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px 0"}}>
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <AccountCircle/>
                                        <p style={{paddingLeft:"20px"}}>{email}</p>
                                    </div>
                                    <Link href={`mailto:${email}`}><Email/></Link>
                                </div>
                                <Divider/>
                            </>
                        })}
                        
                    </CardContent>
                </Card>

                <Card sx={{ maxWidth: 700, width:"30%" ,border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",borderRadius:"20px"}}>
                    <CardHeader title={"SECURITY"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                    <CardContent>
                        {dataMap?.SECURITY?.map((email)=>{
                            return <>
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px 0"}}>
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <AccountCircle/>
                                        <p style={{paddingLeft:"20px"}}>{email}</p>
                                    </div>
                                    <Link href={`mailto:${email}`}><Email/></Link>
                                </div>
                                <Divider/>
                            </>
                        })}
                        
                    </CardContent>
                </Card>

                <Card sx={{ maxWidth: 700, width:"30%" ,border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",borderRadius:"20px"}}>
                    <CardHeader title={"GENERAL"} sx={{backgroundColor:"#6f3e8d",color:"white"}}></CardHeader>
                    <CardContent>
                        {dataMap?.GENERAL?.map((email)=>{
                            return <>
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px 0"}}>
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <AccountCircle/>
                                        <p style={{paddingLeft:"20px"}}>{email}</p>
                                    </div>
                                    <Link href={`mailto:${email}`}><Email/></Link>
                                </div>
                                <Divider/>
                            </>
                        })}
                        
                    </CardContent>
                </Card>
            </div>
            :<></>}
        </Box>
    </>
  )
}

export default Emails