import * as React from 'react';
import {Divider, Toolbar, Typography, Grid, TextField, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Slider, Select} from '@mui/material';
// component import
import PCard from '../components/FeatureCard';
import CFeed from '../components/CameraFeed';
import AddCameraModal from "../components/AddCameraModal"
// import { useState } from "react";
import ImageMarker, { Marker } from "react-image-marker";

const drawerWidth = 240;

export default function Provisioning(props){    
    if (!localStorage.getItem('loggedin')){
        window.location = "/login"
    }

    var [pageData, setPageData] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const [time, setTime] = React.useState(true)
    const [videoFps, setVideoFps] = React.useState(2)
    const [videoLength, setVideoLength] = React.useState(10)
    const [videoResizing, setVideoResizing] = React.useState("original_resolution")
    
    React.useEffect(() => {
        names()
    },[])

    const names = async() => {
        const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_camera/' + window.location.pathname.split("/")[2],{method : "GET"});
        const received_data = (await response.json())['data']
        setPageData(received_data)
        setLoading(false)
    }
    
    const object_alerts = {}
    const [cardData, setCardData] = React.useState({})
    
    if (!loading && time){
        Object.entries(pageData.object_alerts).map(([key,value]) => {
            object_alerts[key] = value
            // object_alerts.push(Object.assign({}, {'name': key}, value))
        });
        setCardData(object_alerts)
        setTime(false)
    }

        const editCamera = () => {
        const features = {"crowd_count":"","crowd_detection":"", "fire_smoke":"", "idle_time":"", "over_manning":"", "ppe_detection":"", "tailgating_vehicle_count":"", "zone_intrusion":"","camera_fault":"","face_mask":"","helmet":"","multiple_person":"","loitering":"","intrusion":""}
        Object.keys(features).forEach(value => {
            if (Object.keys(cardData).includes(value)){
                features[value] = true
            }else{
                features[value] = false
            }
        })
        if (Object.keys(cardData).length==0 || document.getElementById("camera_name").value=="" || document.getElementById("rtsp").value.trim()=="" || document.getElementById("channel_number").value.trim()=="" || document.getElementById("section").value.trim()==""){
            props.openDialog('Fill in details first')
        }
        else{
            const editCamera = async() =>{
                const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/edit_camera/' + window.location.pathname.split("/")[2], {
                    method:"POST",
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({"camera_name":"","camera_json":JSON.stringify({"camera_name": document.getElementById("camera_name").value, "rtsp":document.getElementById("rtsp").value.trim(), "channel_number":document.getElementById("channel_number").value.trim(), "section":document.getElementById("section").value.trim(),"video_fps":videoFps,"video_length":videoLength,"video_res":videoResizing,"all_feature_off":false,"features":features,"object_alerts":cardData,"start_time":"00:00:00", "end_time":"23:59:00"})})
                })
                const returned_response = await response.json()
                if (returned_response.status_code!=200 && returned_response.status_code!=201){
                    props.openDialog(returned_response.message)
                }else{
                    window.location.reload()
                }
            }

            editCamera()
            
        }
    }; 

    const [edit,setEdit] = React.useState(false);
    const handleEdit = () => {
        if (edit==true){
            window.location.reload();
        }
        setEdit(!edit)
    }

    // dialogue
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false)
    };

    const deleteCamera = async() => {
        const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/delete_camera/' + window.location.pathname.split("/")[2], {
            method:"POST",
            headers: {'Content-Type':'application/json'}
        })
        const returned_response = await response.json()
        if (returned_response.status_code!=200 && returned_response.status_code!=201 ){
            props.openDialog(returned_response.message)
        }else{
            window.location.href = "/"
        }
    }


    if (!loading){
        return(
            <>

                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    {pageData.camera_name}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this camera?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteCamera} autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />

                    <div>
                        <Box container sx={{alignItems:"top"}}>
                            <Grid item xs={6}>
                            <div>

                                <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                                    <TextField
                                        label = "Camera Name"
                                        multiline 
                                        id="camera_name"
                                        defaultValue={pageData.camera_name}
                                        variant="outlined"
                                        sx={{mr:4}}
                                        disabled={edit ? false: true}
                                    />
                                    <TextField
                                        label = "RTSP Link"
                                        id="rtsp"
                                        defaultValue={pageData.rtsp.replace(pageData.rtsp.substring(pageData.rtsp.indexOf(":",7) + 1, pageData.rtsp.lastIndexOf("@")),"******")}
                                        variant="outlined"
                                        disabled={edit ? false: true}
                                        sx={{width:"400px"}}
                                    />

                                </div>
                                <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                                    { process.env.REACT_APP_SUFFIX_SHOW == "true" ? 
                                        <>
                                            <TextField
                                                    hiddenLabel
                                                    id="sia_suffix"
                                                    defaultValue={pageData.sia_suffix}
                                                    label="SIA SUFFIX"
                                                    variant="outlined"
                                                    disabled={edit ? false: true}
                                                    sx={{mr:4}}
                                            />
                                        </> : null
                                    }
                                    <TextField
                                        label = "CHANNEL NUMBER"
                                        id="channel_number"
                                        type='number'
                                        defaultValue={pageData.channel_number}
                                        variant="outlined"
                                        disabled={edit ? false: true}
					sx={{mr:4}}
                                    />
                                    <TextField
                                        label = "SECTION NAME"
                                        id="section"
                                        defaultValue={pageData.section}
                                        variant="outlined"
                                        disabled={edit ? false: true}
                                    />

                                </div>
                                <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                                    {process.env.REACT_APP_VIDEO_SAVING == "true" ? 
                                        <>
                                            <Slider sx={{width:"300px",mr:4}} aria-label="Video FPS" id="video_fps" defaultValue={2} valueLabelDisplay="auto" step={1} marks min={1} max={5} onChange={(event) => {(setVideoFps(event.target.value))}}/>
                                            <Select sx={{width:"300px",mr:4}} labelId="video-length-options" id="video_length" value={videoLength} label="Video Length Options" onChange={(event) => {(setVideoLength(event.target.value))}}>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={30}>30</MenuItem>
                                            </Select>
                                            <Select labelId="video-resizing-options" id="video_resizing" value={videoResizing} label="Video Resizing Options" onChange={(event) => {(setVideoResizing(event.target.value))}}>
                                                <MenuItem value="original_resolution">original_resolution</MenuItem>
                                                <MenuItem value="cif_resolution">cif_resolution</MenuItem>
                                                <MenuItem value="4cif_resolution">4cif_resolution</MenuItem>
                                            </Select>
                                        </> : null
                                    }

                                </div>
                                <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                                    <Button variant='outlined' onClick = {edit ? editCamera : handleEdit} sx={{mr:2}}>{edit ? "Save" : "Edit"} Camera</Button>
                                    <Button variant='outlined' onClick={handleClickOpen}>Delete Camera</Button>
                                </div>
                            </div>
                            </Grid>
                        </Box>
                        
                        <Divider/>

                        <div>
                            <div style={{display:"flex",padding:"30px 0"}}>
                                <Typography variant='h4' sx={{width:'fit-content',mr:2}}>Features Activated</Typography>
                                {edit?<AddCameraModal list={cardData} text = "Add" addCard = {setCardData}/>:null}
                            </div>
                            
                            {/* PCards */}
                            <div style={{display:"flex"}}>
                                <div id="cards" style={{display:"flex",flexWrap: "wrap",width:"70%",justifyContent: "space-between",borderRight:"1px solid #e8e8e8"}} >
                                    
                                    {Object.keys(cardData).length==0 ? "Add Features to Camera" : Object.entries(cardData).map(([key,value]) => 
                                        <PCard name = {key} properties = {value} fixed = {edit} list = {cardData} addCard = {setCardData} rtsp = {pageData.rtsp} openDialog={props.openDialog}/>
                                    )}
                                    
                                    <div style={{width:"350px"}}></div>
                                    <div style={{width:"350px"}}></div>
                                    <div style={{width:"350px"}}></div>
                                    <div style={{width:"350px"}}></div>
                                    <div style={{width:"350px"}}></div>
                                </div>

                                {process.env.REACT_APP_FEED_SHOW == "true" ? 
                                    <>
                                        <div id='camera_feed' style={{width:"30%", padding:"0px 20px"}}>
                                        <CFeed id={window.location.pathname.split("/")[2]}/>
                                        </div>
                                    </>
                                    : null
                                }
                                
                            </div>
                        </div>
                    </div>
                    
                </Box>
            </>
        )
    }else{
     return(
        <>
            <p>Loading!!</p>
        </>
     )   
    }
}
