
import React from 'react';

export default function PageNotFound(){
    return(
        <>
            <div style={{margin:"20px"}}>
                <h1>404 Error</h1>
                <h1>Page Not Found</h1>
            </div>
        </>
    )
}