import * as React from 'react';
import { TextField, Button, Grid,Box, Divider, Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slider, Select, MenuItem } from '@mui/material';
// component import
import PCard from '../components/FeatureCard';
import AddCameraModal from "../components/AddCameraModal"


const drawerWidth = 240;

function Main(props) {
    if (!localStorage.getItem('loggedin')){
        window.location = "/login"
    }

    const [open, setOpen] = React.useState(false);
    const [dopen, setDOpen] = React.useState(false);
    const [cardData, setCardData] = React.useState({})
    const [videoFps, setVideoFps] = React.useState(2)
    const [videoLength, setVideoLength] = React.useState(10)
    const [videoResizing, setVideoResizing] = React.useState("original_resolution")

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleDOpen = () => {
        setDOpen(!dopen)
    }

    const [textInput, setTextInput] = React.useState('');

    const handleTextInputChange = event => {
        setTextInput(event.target.value);
    };

     React.useEffect(() => {
         get_template()
     },[])
    const get_template = async() => {
         if (window.location.pathname.split("/").length>2){
            const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_template?id=' + String(window.location.pathname.split("/")[2]),{method : "GET"});
             setCardData((await response.json())['object_alerts'])
//              setLoading(!loading)
         }
     }

    const addCamera = () => {
        const features = {"crowd_count":"","crowd_detection":"", "fire_smoke":"", "idle_time":"", "over_manning":"", "ppe_detection":"", "tailgating_vehicle_count":"", "zone_intrusion":"","camera_fault":"","face_mask":"","helmet":"","multiple_person":"","loitering":"","intrusion":""}
        Object.keys(features).map((value,index) => {
            if (Object.keys(cardData).includes(value)){
                features[value] = true
            }else{
                features[value] = false
            }
        })
        if (Object.keys(cardData).length==0 || document.getElementById("camera_name").value=="" || document.getElementById("rtsp").value.trim()=="" || document.getElementById("channel_number").value.trim()=="" || document.getElementById("section").value.trim()==""){
            props.openDialog('Fill in details first')
        }
        else{
            const sendCamera = async() =>{
                const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/add_camera', {
                    method:"POST",
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({"camera_name":"","camera_json":JSON.stringify({"camera_name": document.getElementById("camera_name").value, "rtsp":document.getElementById("rtsp").value.trim(), "channel_number":document.getElementById("channel_number").value.trim(), "section":document.getElementById("section").value.trim(),"video_fps":videoFps,"video_length":videoLength,"video_res":videoResizing,"all_feature_off":false,"features":features,"object_alerts":cardData,"start_time":"00:00:00", "end_time":"23:59:00"})})
                })
                const returned_response = await response.json()
                if (returned_response.status_code!=200 && returned_response.status_code!=201){
                    props.openDialog(returned_response.message)
                }else{
                    window.location.href = "/"
                }
            }
            sendCamera()
        }
        
    };

    const addTemplate = () => {
        fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/add_template', {
            method:"POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"user":JSON.stringify(Object.assign({},{"template_name":textInput}, {"object_alerts":cardData}))})
        })
        .then(results => {results.json();window.location.href = '/'})
        .catch(error => {
            props.openDialog(error)
        });
        
    };

    const deleteTemplate = () => {
        fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/delete_template/' + window.location.pathname.split("/")[2], {
            method:"POST",
            headers: {'Content-Type':'application/json'}
        })
        .then(results => {results.json();window.location.href = '/'})
        .catch(error => {
            console.log(error)
        });
    }


    
    const [rtsp,setRTSP] = React.useState("")
    const updateRTSP = (event) =>{
        setRTSP(event.target.value)
    }
  return(
    <>
        {/* <div>
            <Dialog open={dopen} onClose={handleDOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                Template
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Template?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDOpen}>Cancel</Button>
                <Button onClick={deleteTemplate} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </div> */}
        {/* <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Save Template</DialogTitle>
                <Divider/>
                <DialogContent>
                <DialogContentText>
                    Name the template
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Template Name"
                    fullWidth
                    variant="standard"
                    onChange= {handleTextInputChange}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={addTemplate}>Save Template</Button>
                </DialogActions>
            </Dialog>
        </div> */}
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
            <Toolbar />
            
            <Box container sx={{alignItems:"top"}}>
                <Grid item xs={6}>
                    <div>
                        <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                            <TextField
                                hiddenLabel
                                id="camera_name"
                                label="Camera Name"
                                variant="outlined"
                                sx={{mr:4}}
                            />
                            <TextField
                                hiddenLabel
                                id="rtsp"
                                label="RTSP LINK"
                                variant="outlined"
                                sx={{width:"400px",mr:4}}
                                onChange = {updateRTSP}
                            />
                        </div>
                        <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                            {process.env.REACT_APP_SUFFIX_SHOW == "true" ? 
                                <>
                                    <TextField
                                            hiddenLabel
                                            id="sia_suffix"
                                            label="SIA SUFFIX"
                                            variant="outlined"
                                            sx={{mr:4}}
                                    />
                                </> : null
                            }

                            <TextField
                                hiddenLabel
                                id="channel_number"
                                type = 'number'
                                label="CHANNEL NUMBER"
                                variant="outlined"
                                sx={{width:"400px",mr:4}}
                            />
                            <TextField
                                hiddenLabel
                                id="section"
                                label="SECTION NAME"
                                variant="outlined"
                            />
                        </div>
                        <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                            {process.env.REACT_APP_VIDEO_SAVING == "true" ? 
                                <>
                                    <Slider sx={{width:"300px",mr:4}} aria-label="Video FPS" id="video_fps" defaultValue={2} valueLabelDisplay="auto" step={1} marks min={1} max={5} onChange={(event) => {(setVideoFps(event.target.value))}}/>
                                    <Select sx={{width:"300px",mr:4}} labelId="video-length-options" id="video_length" value={videoLength} label="Video Length Options" onChange={(event) => {(setVideoLength(event.target.value))}}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                    <Select labelId="video-resizing-options" id="video_resizing" value={videoResizing} label="Video Resizing Options" onChange={(event) => {(setVideoResizing(event.target.value))}}>
                                        <MenuItem value="original_resolution">original_resolution</MenuItem>
                                        <MenuItem value="cif_resolution">cif_resolution</MenuItem>
                                        <MenuItem value="4cif_resolution">4cif_resolution</MenuItem>
                                    </Select>
                                </> : null
                            }

                        </div>
                        <div style={{display:"flex",alignItems:"center",paddingBottom:"20px"}}>
                            <Button variant='outlined' sx={{mr:2}} onClick={addCamera}>Save Camera</Button>
                            {/* <Button variant='outlined' sx={{mr:2}} onClick={handleClickOpen}>Save Template</Button> */}
                            {/* {window.location.pathname.split("/").length>2 ? <Button variant='outlined' onClick={handleDOpen}>Delete Template</Button> : null} */}
                        </div>
                    </div>
                </Grid>
            </Box>

            <div style = {{paddingBottom:"30px"}}>
                <Divider/>
                <div style={{display:"flex",padding:"30px 0",alignItems:"center"}}>
                    <Typography variant='h4' sx={{width:'fit-content',mr:2}}>Features Activated</Typography>
                    <AddCameraModal list = {cardData} text = "Add" addCard = {setCardData} camera_rtsp = {rtsp}/>
                </div>
                <div id = "cards" style={{display:"flex",flexWrap: "wrap",justifyContent: "space-between"}}>
                    {Object.keys(cardData).length==0 ? "Add Features to Camera" : Object.entries(cardData).map(([key,value]) => 
                        <PCard name = {key} properties = {value} fixed = {true} list = {cardData} addCard = {setCardData} openDialog={props.openDialog}/>
                    )}

                    <div style={{width:"350px"}}></div>
                    <div style={{width:"350px"}}></div>
                    <div style={{width:"350px"}}></div>
                    <div style={{width:"350px"}}></div>
                    <div style={{width:"350px"}}></div>
                </div>
            </div>

        </Box>
    </>
  );
}

export default Main;
