import React, { PureComponent } from 'react'
import { ReactDOM } from 'react'
import DCard from './DashboardCard'
import { useState } from 'react';
import { Typography, Divider } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

// middleware component for fetching all cam details from DB and displaying list of dashboard cards
export default function DCards(props){

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(()=>{
        if (window.location.href.split('?').length>1){
            return window.location.href.split('?')[1].split('=')[1];
        }else{
            return 1;
        }
    })

    React.useEffect(() => {
        names()
    },[])

    const names = async() => {
        const response = await fetch('http://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/dashboard?page='+String(page),{method : "GET"});
        const received_data = (await response.json())['data']
        setData(received_data)
        setLoading(!loading)
    }
    
    let filterList;
    if (!loading){
        filterList = []
        data['data'].map((e,i)=>{
            if (!e[1].camera_name.indexOf(props.filter)){
                filterList.push(e)
            }
        })
    }else{
        filterList = []
    }


    return(
        <>
            {loading ? null : filterList.length > 0 ? 
            <>
                {filterList.map((card) => 
                    <DCard data = {card}/>
                )}
                <div style={{'display':"flex",'justifyContent':"flex-end"}}>
                    <Stack spacing={2} paddingY={2}>
                        <Pagination count={Math.max(Math.floor(data['count']/6 + 1),1)} size="large" renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`${item.page === 1 ? '' : `?page=${item.page}`}`}
                                // href={`${item.page === 1 ? '' : `?page=${item.page}`}`}
                                {...item}
                                // page={page}
                                onClick={() => {props.openDialog(JSON.stringify(item));window.location.replace(`?page=${item.page}`)}}
                            />
                        )}/>
                    </Stack>
                </div>
                <Divider/>
            </>
            :<div style={{height:'100%',width:'100%',display:"flex",'alignItems':"center",justifyContent:'center'}}><Typography variant='h6' pt={4}>No Cameras Added Yet</Typography></div>}
        </>
    )
}
