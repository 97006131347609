import * as React from 'react';
import { Divider, Box, Toolbar, Typography, Modal, capitalize,ToggleButton, ToggleButtonGroup, TextField, Button, MenuItem, Select, InputLabel } from '@mui/material';
import { DataGrid, GridToolbarContainer,GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbar} from '@mui/x-data-grid';
// import ImageUpload from "../components/ImageUpload"
import SyncIcon from "@mui/icons-material/Sync";
import { createSvgIcon } from '@mui/material/utils';
import { useSearchParams } from 'react-router-dom';
import './stylesheets/analytics.css'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: "80%",
    maxHeight: "80%",
    width: "auto",
    // height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

const drawerWidth = 240;

var rows = [];
var cards = [{"feature_type": "Total Alerts", "count":"?"}];

const SUBMIT_FILTER_STROKE_TIME = 500;

function Main(props) {
    if (!localStorage.getItem('loggedin')){
        window.location = "/login"
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilterParams = urlParams.get('filter')!=null ? urlParams.get('filter') : "";
    const [totalCount, setTotalCount] = React.useState("");
    const [seed, setSeed] = React.useState("");
    const [data, setData] = React.useState(null);
    const [userSelected, setUserSelected] = React.useState(false);
    const [selectTime, setSelectTime] = React.useState(Date.now());
    const [filterParams, setFilterParams] = React.useState(urlFilterParams);
    const [cluster,setCluster] = React.useState((JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 && JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? 'All' : JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 ? "NCL" : JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0 ? "SECL" : 'All')
    const [selectedSite,setSelectedSite] = React.useState("All")
    const [feature,setFeature] = React.useState("")

    const dateConstruct = new Date();
    let day = dateConstruct.getDate() < 10 ? `0${dateConstruct.getDate()}`: dateConstruct.getDate() ;
    let month = dateConstruct.getMonth() + 1 < 10 ? `0${dateConstruct.getMonth() + 1}`:dateConstruct.getMonth() + 1 ;
    let year = dateConstruct.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`;

    const [startDate,setStartDate] = React.useState(currentDate);
    const [endDate,setEndDate] = React.useState(currentDate)

    var filterModelParams;
    if (filterParams!=""){
      filterModelParams = { columnField: filterParams.split(' ')[0], operatorValue: filterParams.split(' ')[1], value: filterParams.split(' ')[2] }
    }else{
      filterModelParams = {}
    }
    const [currentPage, setCurrentPage] = React.useState(0)

    const [loading, setLoading] = React.useState(true)
    const [imgData, setimgData] = React.useState({'imgPath':"NOT SAVED",'vidPath':"NOT SAVED",'alertType':"?",'cameraName':"?",'alertTimestamp':"?",'since': "?"})

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (loading){
                names()
            }
        }, 2000);
      
        return () => clearInterval(interval);
    }, [loading]);

    React.useEffect(() => {
      names()
    }, [feature,cluster,selectedSite,startDate,endDate,currentPage]);

    const names = async() => {
        setLoading(false)
        const response = await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + `/camera_faulty?cluster=${cluster == 'All' ? null : cluster}&site=${selectedSite == 'All' ? null :selectedSite}&cluster_access=${Object.keys(JSON.parse(window.localStorage.getItem('site_access'))).filter(k => JSON.parse(window.localStorage.getItem('site_access'))[k].length>0).join(',')}&site_access=${getSites()}`,{method : "GET"});
        // const response = (filterParams == null ? await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+":"+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_alerts?page='+currentPage,{method : "GET"}) : await fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + '/get_alerts?page='+currentPage+'&filter='+String(filterParams),{method : "GET"}))
        const received_data = (await response.json())['data']
        console.log(received_data)
        setData(received_data)
        loadRows(received_data, selectedSite)
    }
    const exportCsv = () => {
      fetch('https://'+process.env.REACT_APP_PRIVATE_IP+':'+process.env.REACT_APP_PORT+ '/' + process.env.REACT_APP_API_NAME + `/camera_faulty?cluster=${cluster == 'All' ? null : cluster}&site=${selectedSite == 'All' ? null :selectedSite}&export=${true}&cluster_access=${Object.keys(JSON.parse(window.localStorage.getItem('site_access'))).filter(k => JSON.parse(window.localStorage.getItem('site_access'))[k].length>0).join(',')}&site_access=${getSites()}`,{method : "GET"})
      .then(response => {
      return response.blob();
        
      })
      .then(blob => {
        var url = window.URL.createObjectURL(new Blob([blob]));
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download',"export.csv")
        document.body.appendChild(a); // append the element to the dom
        handleClose()
        a.click();
        a.parentNode.removeChild(a);
      })
      .catch(error => {
        console.error(error);
      });
    }

    const loadRows = (data, site) => {
        const temp = []
        data['alert_data'].map((value,index) => {
          temp.push(value)
        })
        rows  =  temp
        let count = 0;
        let site_count = 0;
        data['count_data'].map((value,index) => {
          if (site.toUpperCase()==value['feature_type']){
            site_count = value['count']
          }
          count+=value['count']
        })

        if (site=="All"){
          setTotalCount(count)
        }else{
          setTotalCount(site_count)
        }
        
        cards = [{'feature_type':'No. of Camera Faulty', "count": count}].concat(data['count_data'])
        if (!userSelected || (Date.now() - selectTime == 15)){
          setimgData({'imgPath':process.env.REACT_APP_SFTP_URL+temp[0]['image_path'],'vidPath':process.env.REACT_APP_SFTP_URL+temp[0]['video_path'],'alertType':temp[0]['feature_type'],'cameraName':temp[0]['camera_name'],'alertTimestamp':temp[0]['alert_timestamp'],'since':temp[0]['since']})
        }
        setLoading(true)

    }

    let columns;
    columns = [
        { field: 'feature_type', headerName: 'Feature Type', flex:0.2, minWidth:120 },
        { field: 'cluster', headerName: 'Cluster', flex:0.12, minWidth:120},
        { field: 'site', headerName: 'Site', flex:0.12, minWidth:120},
        { field: 'camera_name', headerName: 'Camera Name', flex:0.2, minWidth:120 },
        // { field: 'ip', headerName: 'Camera IP', flex:0.2, minWidth:50 },
        // { field: 'channel', headerName: 'Channel Number', flex:0.12 },
        { field: 'alert_timestamp', headerName: 'Offline Till', flex:0.2, minWidth:120, type: 'datetime',valueGetter: ({ value }) => value && new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'})},
        { field: 'since', headerName: 'Offline since', flex:0.2, minWidth:120, type: 'datetime', valueGetter: ({ value }) => value && new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'})},
        // { field: 'crowd_count', headerName: 'Crowd Count', flex:0.12,minWidth:80}
    ];

    const siteNamesNcl = JSON.parse(window.localStorage.getItem('site_access'))["NCL"]
    const siteNamesSecl = JSON.parse(window.localStorage.getItem('site_access'))["SECL"]
    const getSites = () => {
        if(cluster == 'All') return [...siteNamesNcl,...siteNamesSecl]
        if(cluster == 'NCL') return siteNamesNcl
        
        return siteNamesSecl
    }

    const handleImgData = (ids) => {
        setimgData({'imgPath':process.env.REACT_APP_SFTP_URL+ids['row']['image_path'],'vidPath':process.env.REACT_APP_SFTP_URL+ids['row']['video_path'],'alertType':ids['row']['feature_type'],'cameraName':ids['row']['camera_name'],'alertTimestamp':ids['row']['alert_timestamp'],'since':ids['row']['since']})
        setUserSelected(true)
        document.getElementById('alert_image').click()
        setSelectTime(Date.now())
    }

    const onFilterChange = React.useCallback((filterModel) => {
      if ((filterModel['items'].length!=0)){
        setFilterParams(filterModel['items'][0]['columnField'] + '+' + filterModel['items'][0]['operatorValue'] + '+' + (filterModel['items'][0]['value']!=undefined ? filterModel['items'][0]['value'] : ""))
        names(0, filterModel=filterModel['items'][0]['columnField'] + '+' + filterModel['items'][0]['operatorValue'] + '+' + (filterModel['items'][0]['value']!=undefined ? filterModel['items'][0]['value'] : ""))
      }
    }, []);

    const onPaginationChange = React.useCallback((newPage) => {
        setCurrentPage(newPage)
    },[])

    const [alignment, setAlignment] = React.useState('image');

    const handleToggleChange = (event, newAlignment) => {
        if (newAlignment!=null){
          setAlignment(newAlignment);
        }
    };
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarDensitySelector />
            <Button color='primary' size="small" startIcon={<ExportIcon/>} onClick={exportCsv}>Export CSV</Button>
            {/* <Button color='primary' size="small" startIcon={<ExportIcon/>} onClick={() => alert('Export PDF')}>Export PDF</Button> */}
            {/* <GridToolbarExport /> */}
          </GridToolbarContainer>
        );
      }
    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <img style={style} src={imgData['imgPath']=="NOT SAVED" ? "noimage.jpeg" : imgData['imgPath']}></img>
            </Modal>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                <div id='headerAD'>
                    <div id='clusterUMSection'>
                    {(JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 && JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0) ? <><Button className={ cluster === 'All' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('All');}}>All</Button></> : null}
                      {JSON.parse(window.localStorage.getItem('site_access'))["NCL"].length > 0 ? <><Button className={ cluster === 'NCL' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('NCL');}}>NCL</Button></> : null}
                      {JSON.parse(window.localStorage.getItem('site_access'))["SECL"].length > 0 ? <><Button className={ cluster === 'SECL' ? 'active' : ''} variant="outlined" sx={{width:"100px",height:"40px"}} onClick={() => {setCluster('SECL');}}>SECL</Button></> : null}
                    </div>

                    <div  style={{minWidth:"200px",color:"black",fontSize:"16px"}}>
                        <Select name='Site' id="siteFilter" label="Site" value={selectedSite} onChange={(e) => setSelectedSite(e.target.value)} sx={{width:'100%'}}>
                            <MenuItem value={"All"}>All</MenuItem>
                            {getSites().map((site) => 
                                <MenuItem value={site}>{site}</MenuItem>
                            )}
                        </Select>
                    </div>
                </div>                

                <div id="postHeaderAD" >
                  <div id = "cards" className="cardsAD" style={{width:"100%"}}> 
                      {cards.map((value, index) =>
                          <>
                            <div onClick={value['feature_type']!='No. of Camera Faulty' ? (event)=>{selectedSite==value['feature_type'].charAt(0)+ value['feature_type'].slice(1).toLowerCase() ? setSelectedSite("All") : setSelectedSite(value['feature_type'].charAt(0)+ value['feature_type'].slice(1).toLowerCase());} : null} class="alertCard" id={index} style={selectedSite==value['feature_type'].charAt(0)+ value['feature_type'].slice(1).toLowerCase() ? {border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", minWidth:"200px", display:'flex',flexDirection:"column",padding:"20px",marginBottom:'20px',marginRight:'20px',backgroundColor:"#e8e8e8"} : {border:"1px solid #e8e8e8",boxShadow:"5px 5px 5px whitesmoke",width:"30%", minWidth:"200px", display:'flex',flexDirection:"column",padding:"20px",marginBottom:'20px',marginRight:'20px'}}>
                                <Typography variant='h6'>{capitalize(value['feature_type'])}</Typography>
                                <Typography variant='h4' align="right">{value['count']}</Typography>
                            </div>
                          </>
                      )}
                      <div style={{width:"30%",minWidth:"200px",marginRight:'20px',height:"1px"}}></div>
                      <div style={{width:"30%",minWidth:"200px",marginRight:'20px',height:"1px"}}></div>
                  </div>
                </div>
                
                <Divider/>
                
                <div id='alertMain' >
                    <div id="alertGrid" style={{width:"70%",height: "600px"}}>
                        <DataGrid
                            disableColumnFilter 
                            getRowId={(r) => r.ID}
                            // loading = {true}
                            rows={rows}
                            columns={columns}
                            pageSize={100}
                            rowsPerPageOptions={[100]}
                            sx = {{fontSize:"1rem"}}
                            onRowClick = {(ids) => {handleImgData(ids)}}
                            components={{
                                // Toolbar: GridToolbar,
                                Toolbar: CustomToolbar,
                              }}
                            componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },csvOptions: { allRows: true } } }}
                            initialState={{
                                filter:{
                                    filterModel: {items: [filterModelParams],},
                                }
                            }}
                            filterMode="server"
                            onFilterModelChange={onFilterChange}
                            paginationMode="server"
                            onPageChange={onPaginationChange}
                            rowCount={totalCount} 

                        />
                    </div>
                    <div id="alertImage" style={{width:"30%"}}>
                        <div style={{marginBottom:"20px"}}>
                            <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleToggleChange} aria-label="Platform" style={{width:"100%"}}>
                                <ToggleButton value="image" id="alert_image" style={{width:"50%"}}>Image</ToggleButton>
                                <ToggleButton value="video" id="alert_video" style={{width:"50%"}}>Video</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",maxWidth:"100vw"}}>
                            {alignment=='image' ? <img style={{maxWidth:"100%",height:"auto"}} src={imgData['imgPath']=="NOT SAVED" ? process.env.REACT_APP_SFTP_URL + "noimage.jpeg" : "https://assets.dev.deepsightlabs.com/sfpt-user/Projects/alert/NCL/DUDHICHUA/57_20230608-14-47-24.jpg"} alt="alert_image" onClick={handleOpen}/> : 
                            <video controls>
                                <source src={imgData['vidPath']=="NOT SAVED" ? process.env.REACT_APP_SFTP_URL + "noimage.jpeg" : imgData['vidPath']} type="video/mp4"/>
                                Your browser does not support HTML video.
                            </video>}
                            <span id='alert_type' style={{color:'red',textTransform:"capitalize"}}>Alert Type : {imgData['alertType']}</span>
                            <span id='camera_id' style={{color:'green',textTransform:"capitalize"}}>Camera Name : {imgData['cameraName']}</span>
                            <span id='alert_timestamp' style={{color:'blue',textTransform:"capitalize"}}>Offline Till : {new Date(imgData['alertTimestamp']).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'})}</span>
                            <span id='since' style={{color:'blue',textTransform:"capitalize"}}>Offline Since : {new Date(imgData['since']).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'})}</span>
                        </div>
                    </div>
                    <div>
                      
                  </div>
                </div>

                
                {/* <ImageUpload /> */}
            </Box>
        </>
    );
}

export default Main;
